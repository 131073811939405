<template>
	<div
		class="mt-30 text-left flex-row"
	>
		<MypageSide
			:program="program"
			:user="user"
		></MypageSide>

		<div
			class="flex-1 bg-white pa-24 radius-20"
		>
			<div>
				<div class="under-line-bbb pb-16 flex-row">
					<h3 class="flex-1 size-px-20">{{ program.title }}</h3>
				</div>
				<div class="full-height flex-column">
					<div
						class=""
					>
						<div class="">
							<DatePicker class="mt-20" type="year" :date="search_year" @click="setDate($event)" ></DatePicker>
						</div>

						<div
							class="mt-20"
						>
							<table
								v-if="items.length > 0"
								class="table table-odd"
							>
								<thead>
									<tr>
										<th>결제수단</th>
										<th>결제금액</th>
										<th>거래일시</th>
										<th>결제상태</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(history, h_index) in list_history"
										:key="'history_' + h_index"
										class="under-line-not-last pb-20 mb-20"
										@click="onPayment(history)"
									>
										<td>
											<div
											>{{ history.payment_type_code_name }}</div>
										</td>
										<td>
											<div>{{ history.payment_amount | makeComma }}원</div>
										</td>
										<td>
											<div>{{ history.pmtdate }}</div>
										</td>
										<td>
											<div :class="history.payment_state_color">{{ history.payment_state_code_name }}</div>
										</td>
									</tr>
								</tbody>
							</table>
							<Empty
								v-else
							>
							</Empty>
						</div>
					</div>

					<PopupLayer
						v-if="is_on_payment"
					>
						<template
							v-slot:body
						>
							<div
								class="width-500 ma-auto bg-white radius-20 full-height flex-column"
								style="box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);"
							>
								<div
									class="size-px-20 text-center font-weight-bold under-line pb-20 
									bg-mafia-blue flex-row justify-space-between items-center pa-13-30 pr-20 position-relative"
								>
									<h4 class="color-white">
										결제 상세 내역
									</h4>
									<div class="popup-close">
										<img class="cursor-pointer"
										@click="offPayment"
										:src="require('@/assets/image/web/notify_cartel/ico_close.svg')"/>
									</div>
								</div>

								<div
									class="size-px-14 full-height flex-column pa-30"
								>
									<div>
										<div class="under-line pb-20">
											<div class="size-px-16 font-weight-bold">정보</div>
											<div class="mt-15 justify-space-between">
												<span>상품</span>
												<span class="font-weight-500">{{ item_payment.point_name }} {{ item_payment.charg_point }}개</span>
											</div>
										</div>
										<div class="mt-20">
											<div class="size-px-16 font-weight-bold">결제정보</div>
											<div class="mt-10 justify-space-between">
												<span>거래번호</span>
												<span class="font-weight-500">{{ item_payment.order_number }}</span>
											</div>
											<div class="mt-10 justify-space-between">
												<span>카드</span>
												<span class="font-weight-500">{{ item_payment.payment_mthd_name }}</span>
											</div>
											<div class="mt-10 justify-space-between">
												<span>승인번호</span>
												<span class="font-weight-500">{{ item_payment.payment_approval_number }}</span>
											</div>
											<div class="mt-10 justify-space-between">
												<span>거래종류</span>
												<span class="font-weight-500">{{ installment }}</span>
											</div>
											<div class="mt-10 justify-space-between">
												<span>결제금액</span>
												<span class="font-weight-500">{{ item_payment.amount | makeComma }}</span>
											</div>
											<div class="mt-10 justify-space-between">
												<span>거래일시</span>
												<span class="font-weight-500">{{ item_payment.pmtdate }}</span>
											</div>
										</div>
									</div>

									<div
										class="mt-auto btn_area pt-30 text-center"
									>
										<button
											class="btn-inline btn-primary radius-20 pa-13-30 font-weight-500"
											style="outline: none;"
											@click="offPayment"
										>확인</button>
									</div>
								</div>
							</div>
						</template>
					</PopupLayer>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import PopupLayer from "@/view/Layout/PopupLayer";
import DatePicker from "@/components/DatePicker";
import MypageSide from "@/view/Mypage/mypage-lnb";
import Empty from "@/view/Layout/Empty";
export default {
	name: 'PaymentHistory'
	, components: {Empty, MypageSide, DatePicker, PopupLayer}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'payment history'
				, title: '불렛 결제내역'
				, not_footer: true
				, type: 'mypage'
				, bg_contents: 'bg-gray01'
			}
			, items: [
				{ point: 100, amount: 100, installment: '3개월', order_number: '123456789', card_number: '카드1234', confirm_number: '123123', type: '+', type_name: '신용카드', pay_type: 'card', pay_type_name: '신용카드', wDate: '2023.06.01 18:20:11'}
				, { point: 200, amount: 2000, installment: '일시불', order_number: '123456789', card_number: '카드1234', confirm_number: '123123', type: '+', type_name: '신용카드', pay_type: 'card', pay_type_name: '오픈 이벤트', wDate: '2023.06.01 18:20:11'}
				, { point: 300, amount: 3000, installment: '3개월', order_number: '123456789', card_number: '카드1234', confirm_number: '123123', type: '-', type_name: '신용카드', pay_type: 'card', pay_type_name: '플랜 구독', wDate: '2023.06.01 18:20:11'}
				, { point: 400, amount: 4000, installment: '3개월', order_number: '123456789', card_number: '카드1234', confirm_number: '123123', type: '-', type_name: '신용', pay_type: 'card', pay_type_name: '카르텔 후원', wDate: '2023.06.01 18:20:11'}
				, { point: 500, amount: 5000, installment: '3개월', order_number: '123456789', card_number: '카드1234', confirm_number: '123123', type: '+', type_name: '용', pay_type: 'card', pay_type_name: '인앱', wDate: '2023.06.01 18:20:11'}
				, { point: 600, amount: 6000, installment: '3개월', order_number: '123456789', card_number: '카드1234', confirm_number: '123123', type: '+', type_name: '카', pay_type: 'card', pay_type_name: '신용카드', wDate: '2023.06.01 18:20:11'}
			]
			, is_on_payment: false
			, item_payment: {

			}
			, search_year: ''
		}
	}
	, computed: {
		installment: function(){
			let t = '-'
			if(this.item_payment.installment > 0){
				t = this.item_payment.installment + '개월'
			}else{
				t = '일시불'
			}
			return t
		}
		, list_history: function(){
			return this.items.filter( (item) => {
				switch (item.payment_state_code){
					case 'PM00400007':
						item.payment_state_color = 'color-red-dc'
						break;
				}

				return item
			})
		}
	}
	, methods: {

		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_user_payment_list
					, data: {
						member_number: this.user.member_number
						, search_year: this.search_year + ''
					}
					, type: true
				})

				if(result.success){
					this.items = result.data.point_payment_particulars_list
					if(this.items.length > 0){
						this.item_last = this.items[this.items.length - 1]
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, onPayment: async function(item){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_user_payment_detail
					, data: {
						member_number: this.user.member_number
						, point_number: item.point_number
						, charg_payment_number: item.charg_payment_number
						, payment_type_code: item.payment_type_code
					}
					, type: true
				})

				if(result.success){
					this.is_on_payment = true
					this.item_payment = result.data
					this.item_payment.order_number = item.charg_payment_number
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, offPayment: function(){
			this.is_on_payment = false
			this.item_payment = {}
		}
		, to: function(type){
			this.$bus.$emit('to', { name: 'PaymentHistory', params: { type: type}})
		}
		, setDate: function(date){
			this.search_year = date
			this.getData()
		}
	}
	, created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>
<style scoped>
.table-odd tbody tr:nth-child(odd){background-color:unset;}
.table-odd tbody tr:hover{background-color:rgba(23, 28, 97, 0.03);}
</style>