<template>
	<div>
		<div
			class="flex-column justify-center items-center size-px-20"
		>
			<div>
				<button
					class=" mr-10"
					@click="prev"
				><v-icon>mdi-chevron-left</v-icon></button>
				<button
				>
					<span class="box-date bg-white size-px-14 mr-5"> {{ date_picker.date }}</span>
					<v-icon class="size-px-20">mdi-calendar-month</v-icon>
				</button>
				<button
					class=" ml-10"
					@click="next"
				><v-icon>mdi-chevron-right</v-icon></button>
			</div>
		</div>

		<PopupLayer
			v-if="is_view"
		>
			<template
				v-slot:body
			>
				<div
					v-if="type == 'year'"
				>
					<button
					></button>
				</div>
				<v-date-picker
					v-else
					v-model="date_picker.date"
					no-title
					scrollable
					@change="setDate"
					style="width: 290px"
					year-format
				></v-date-picker>
			</template>
		</PopupLayer>
	</div>
</template>

<script>
import PopupLayer from "@/view/Layout/PopupLayer";
export default {
	name: 'DatePicker'
	, components: {PopupLayer}
	, props: ['type', 'date']
	, data: function(){
		return {
			is_view: false
			, year: this.type == 'year' ? true : false
			, date_picker: {
				date: this.date ? this.date : this.type == 'year' ? this.$date.init().year : this.$date.getToday('-')
			}
		}
	}
	, computed: {

	}
	, methods: {
		setDate: function(date){
			this.$emit('click', date)
			this.is_view = false
		}
		, next: function(){
			let date = this.$date.init()
			let max = date.year

			if(this.date_picker.date >= max){
				return false
			}else{
				this.date_picker.date++
				this.setDate(this.date_picker.date)
			}
		}
		, prev: function(){
			this.date_picker.date--
			this.setDate(this.date_picker.date)
		}
	}
	, created() {
	}
}
</script>

<style>
.v-date-picker-table .v-btn.v-btn--active { color: black !important;}
</style>